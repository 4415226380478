
@import "./../scss/required";

.price {
    display: block;
    line-height: 1;
    color: $primary-2;
}

.price-value {
    display: inline-flex;

    small {
        font-size: 65%;
    }
}

.price-discount {
    .price-value,
    .price-currency {
        position: relative;

        &::before {
            position: absolute;
            top: 50%;
            width: 100%;
            height: 1px;
            content: "";
            background-color: $gray-600;
        }
    }
}

.price-ref {
    color: $gray-600;
    text-transform: capitalize;
}

.price-listing {
    font-weight: $font-weight-bold;
}

.price-product {
    font-size: $h3-font-size;
    text-transform: capitalize;
}

.price-product-mini {
    font-weight: $font-weight-bold;
    text-transform: capitalize;
}
